
import animations from "./animations";
import { defineComponent } from 'vue';
import { UiTile } from '@/components';

export default defineComponent({
  name: 'HowItWorks',
  components: {
    UiTile,
  },
  computed: {
    dict() {
      return this.$i.tr().howItWorks;
    },
  },
  mounted() {
    if (window.innerWidth < 600) animations.animBoxScrollMobile(this.$refs);
    else animations.animBoxScrollDesktop(this.$refs);
  },
});
