
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'About',
  computed: {
    dict() {
      return this.$i.tr().about;
    },
  },
});
