<template>
  <div class="ui-tile">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiTile',
});
</script>

<style lang="scss" src="./styles.scss" />
