<template>
  <div class="section-mobile">
    <h2>
      <FuncTextWithSlots :items="dict.title">
        <template #bold="{data}">
          <b>{{ data }}</b>
          <br>
        </template>
      </FuncTextWithSlots>
    </h2>
    <div class="content hide-mobile">
      <div class="content__box">
        <div class="application wow slideInLeft">
          <div class="application__ico">
            <img
              src="~@/assets/images/logo/mobile-apple-logo.svg"
              alt="apple logo"
            >
          </div>
          <div class="application__content">
            <p>
              <FuncTextWithSlots :items="dict.ios">
                <template #bold="{data}">
                  <b>{{ data }}</b>
                </template>
              </FuncTextWithSlots>
            </p>
          </div>
          <div class="application__footer">
            <a
              href="https://apps.apple.com/pl/app/mydr/id1482090318?l=pl"
              target="_blank"
            ><button class="store-btn"><img
              src="~@/assets/icons/mobile-app-store.svg"
              alt="app store"
            ></button></a>
          </div>
        </div>
      </div>
      <div class="content__box content__box--illustration">
        <img
          src="~@/assets/images/decorations/mobile-phones.svg"
          alt="phones"
        >
      </div>
      <div class="content__box">
        <div class="application wow slideInRight">
          <div class="application__ico">
            <img
              src="~@/assets/images/logo/mobile-android-logo.svg"
              alt="android logo"
            >
          </div>
          <div class="application__content">
            <p>
              <FuncTextWithSlots :items="dict.android">
                <template #bold="{data}">
                  <b>{{ data }}</b>
                </template>
              </FuncTextWithSlots>
            </p>
          </div>
          <div class="application__footer">
            <a
              href="https://play.google.com/store/apps/details?id=com.lekseek.mydr"
              target="_blank"
            ><button class="store-btn"><img
              src="~@/assets/icons/mobile-google-play.svg"
              alt="google play store"
            ></button></a>
          </div>
        </div>
      </div>
    </div>
    <div class="content hide-desktop">
      <div class="content__box content__box--mobile">
        <div class="application wow slideInLeft">
          <div class="application__ico">
            <img
              src="~@/assets/images/logo/mobile-apple-logo.svg"
              alt="apple logo"
            >
          </div>
          <div class="application__content">
            <p>
              <FuncTextWithSlots :items="dict.ios">
                <template #bold="{data}">
                  <b>{{ data }}</b>
                </template>
              </FuncTextWithSlots>
            </p>
          </div>
          <div class="application__footer">
            <a
              href="https://apps.apple.com/pl/app/mydr/id1482090318?l=pl"
              target="_blank"
            ><button class="store-btn"><img
              src="~@/assets/icons/mobile-app-store.svg"
              alt="app store"
            ></button></a>
          </div>
        </div>
        <img
          class="mobile-illustration"
          src="~@/assets/images/decorations/mobile-single-apple.svg"
          alt="Iphone"
        >
      </div>
      <div class="content__box content__box--mobile content__box--mobile-reverse">
        <div class="application wow slideInRight">
          <div class="application__ico">
            <img
              src="~@/assets/images/logo/mobile-android-logo.svg"
              alt="android logo"
            >
          </div>
          <div class="application__content">
            <p>
              <FuncTextWithSlots :items="dict.android">
                <template #bold="{data}">
                  <b>{{ data }}</b>
                </template>
              </FuncTextWithSlots>
            </p>
          </div>
          <div class="application__footer">
            <a
              href="https://play.google.com/store/apps/details?id=com.lekseek.mydr"
              target="_blank"
            ><button class="store-btn"><img
              src="~@/assets/icons/mobile-google-play.svg"
              alt="google play store"
            ></button></a>
          </div>
        </div>
        <img
          class="mobile-illustration"
          src="~@/assets/images/decorations/mobile-single-android.svg"
          alt="Android phone"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { FuncTextWithSlots } from '@/components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Mobile',
  components: {
    FuncTextWithSlots,
  },
  computed: {
    dict() {
      return this.$i.tr().mobile;
    },
  },
});
</script>

<style lang="scss" src="./styles.scss" />
