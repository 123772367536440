
import { defineComponent, PropType } from 'vue';

export default defineComponent({

  name: 'FuncSkew',
  props: {
    angle: {
      type: Number as PropType<number>,
      default: -5,
    },
  },
  computed: {
    level(): number {
      return -this.angle;
    },
  },
});
