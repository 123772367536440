<template>
  <div class="section-specialist">
    <div class="section-specialist__left">
      <h1>
        <FuncTextWithSlots :items="dict.title">
          <template #bold="{data}">
            <br>
            <b>{{ data }}</b>
          </template>
        </FuncTextWithSlots>
      </h1>
      <a :href="URI_REGISTER"><UiBtn
        type="search"
        class="cta-btn"
        :default-icon="true"
      >{{ dict.signup }}</UiBtn></a>
      <div class="mobile-map hide-desktop">
        <img
          src="~@/assets/images/decorations/map.svg"
          alt="asset"
          class="map"
        >
        <SpecialistsMap />
      </div>
      <div
        ref="stats"
        class="statistics"
      >
        <div class="statistic-box">
          <img
            src="~@/assets/icons/visit-seth.svg"
            alt="asset"
          >
          <h3>{{ stats.docs }}</h3>
          <p>{{ dict.doctors }}</p>
        </div>
        <div class="statistic-box">
          <img
            src="~@/assets/icons/visit-heart.svg"
            alt="asset"
          >
          <h3>{{ stats.specs }}</h3>
          <p>{{ dict.specialties }}</p>
        </div>
        <div class="statistic-box">
          <img
            src="~@/assets/icons/visit-pointer.svg"
            alt="asset"
          >
          <h3>{{ stats.cities }}</h3>
          <p>{{ dict.locations }}</p>
        </div>
      </div>
    </div>
    <div class="section-specialist__right hide-mobile">
      <img
        src="~@/assets/images/decorations/map.svg"
        alt="asset"
        class="map"
      >
      <SpecialistsMap />
    </div>
  </div>
</template>

<script>
import { ExternalLinks } from '@/config';
import { FuncTextWithSlots, UiBtn } from '@/components';
import { SpecialistsMap } from "./_components";
import animations from "./_animations";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Specialist',
  components: {
    FuncTextWithSlots,
    UiBtn,
    SpecialistsMap,
  },
  data() {
    return {

      stats: {
        docs: 0,
        specs: 0,
        cities: 0,
      },

    };
  },
  computed: {
    URI_REGISTER: () => ExternalLinks.MYDR_REGISTER,
    dict() {
      return this.$i.tr().specialists;
    },
  },
  mounted() {
    animations.animNums(this.stats, this.$refs.stats);
  },
});
</script>

<style lang="scss" src="./styles.scss" />
