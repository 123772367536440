import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

/*eslint-disable*/

const animations = {
    animPinsScroll(refs) {
        const { pin: pins } = refs;

        gsap.fromTo(
            pins,
            {
                y: -200,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: pins,
                    start: "bottom 50%",
                },
                stagger: {
                    amount: 1,
                },
                ease: "power1.out",
            },
        );
    },

    animNums(state, statsRef) {
        const vals = {
            docs: { val: 0 },
            specs: { val: 0 },
            cities: { val: 0 },
        };
        const endVals = {
            docs: 10344,
            specs: 12,
            cities: 45,
        };

        gsap.timeline({
            scrollTrigger: {
                trigger: statsRef,
                start: "bottom 100%",
                toggleActions: "play complete",
            },
        }).to(vals.docs, 2, {
            val: endVals.docs,
            roundProps: "val",
            onUpdate: function() {
                state.docs = vals.docs.val;
            },
        });

        gsap.timeline({
            scrollTrigger: {
                trigger: statsRef,
                start: "bottom 100%",
                toggleActions: "play complete",
            },
        }).to(vals.specs, 2, {
            val: endVals.specs,
            roundProps: "val",
            onUpdate: function() {
                state.specs = vals.specs.val;
            },
        });

        gsap.timeline({
            scrollTrigger: {
                trigger: statsRef,
                start: "bottom 100%",
                toggleActions: "play complete",
            },
        }).to(vals.cities, 2, {
            val: endVals.cities,
            roundProps: "val",
            onUpdate: function() {
                state.cities = vals.cities.val;
            },
        });
    },
};

export default animations;
