import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "view-welcome" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_FuncSkew = _resolveComponent("FuncSkew")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_Benefits = _resolveComponent("Benefits")!
  const _component_Specialists = _resolveComponent("Specialists")!
  const _component_Offer = _resolveComponent("Offer")!
  const _component_Mobile = _resolveComponent("Mobile")!
  const _component_About = _resolveComponent("About")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_FuncSkew, { id: "hero" }, {
      default: _withCtx(() => [
        _createVNode(_component_Hero)
      ]),
      _: 1
    }),
    _createVNode(_component_FuncSkew, { id: "how-it-works" }, {
      default: _withCtx(() => [
        _createVNode(_component_HowItWorks)
      ]),
      _: 1
    }),
    _createVNode(_component_Benefits),
    _createVNode(_component_FuncSkew, {
      id: "specialists",
      angle: 5
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Specialists)
      ]),
      _: 1
    }),
    _createVNode(_component_Offer, { id: "offer" }),
    _createVNode(_component_FuncSkew, { id: "mobile-app" }, {
      default: _withCtx(() => [
        _createVNode(_component_Mobile)
      ]),
      _: 1
    }),
    _createVNode(_component_About, { id: "about-us" }),
    _createVNode(_component_Footer)
  ]))
}