import { LocaleOptions } from "@/config";
import { useTranslator } from "@/plugins";

export function useLocale() {

  const i = useTranslator();

  function setLocale(locale: LocaleOptions) {
    i.locale = locale;
    localStorage.setItem('locale', locale);
    document.documentElement.lang = locale;
  }

  return {
    setLocale,
  };
}
