
import { defineComponent } from 'vue';

type Link = {
  name: string;
  url: string;
  class?: string;
  icon?: string;
  badge?: string;
};

type Group = {
  name: string;
  links: Link[];
}

export default defineComponent({
  name: 'FooterNav',
  computed: {
    groups() {
      return [
        {
          name: this.$i.tr().footer.nav.doctors.title,
          links: [
            {
              name: this.$i.tr().footer.nav.doctors.drw,
              url: 'https://edm.mydr.pl/',
              class: 'drw',
              badge: 'logo/mydr-edm.svg',
            },
          ],
        },
        {
          name: this.$i.tr().footer.nav.etc,
          links: [
            {
              name: 'www.dr100.pl',
              url: 'https://dr100.pl/',
            },
            {
              name: 'www.zdrowastrona.pl',
              url: 'https://zdrowastrona.pl/',
            },
            {
              name: 'www.lekseniora.pl',
              url: 'http://lekseniora.pl/',
            },
            {
              name: 'www.edm.mydr.pl',
              url: 'https://edm.mydr.pl/',
            },
          ],
        },
      ] as Group[];
    },
  },
});
