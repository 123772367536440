import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "func-skew",
    style: _normalizeStyle(`transform: skew(0deg, ${_ctx.angle}deg);`)
  }, [
    _createElementVNode("div", {
      class: "func-skew__wrapper",
      style: _normalizeStyle(`transform: skew(0deg, ${_ctx.level}deg);`)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ], 4))
}