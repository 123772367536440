
import { defineComponent } from 'vue';
import { FooterNav } from './Nav';

export default defineComponent({
  name: 'Footer',
  components: {
    FooterNav,
  },
  data() {
    return {
      footerItems: [
        {
          text: 'kontakt@mydr.pl',
          link: 'mailto:kontakt@mydr.pl',
          icon: 'envelope',
        },
        {
          text: 'Polityka prywatności',
          link: '/static/mydr_privacy_policy.pdf',
        },
      ],
    };
  },
});
