import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "locale-switch",
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleMousemove(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleMousemove(false)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.isExpanded ? 'locale-switch__items--expanded' : '', "locale-switch__items"])
    }, [
      _createElementVNode("img", {
        src: require(`@/assets/images/flags/${_ctx.selectedLocaleOption.file}`),
        class: "locale-switch__item"
      }, null, 8, _hoisted_1),
      _createVNode(_Transition, { name: "slide" }, {
        default: _withCtx(() => [
          (_ctx.isExpanded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherLocaleOptions, (o, i) => {
                  return (_openBlock(), _createElementBlock("img", {
                    key: i,
                    src: require(`@/assets/images/flags/${o.file}`),
                    class: "locale-switch__item locale-switch__item--clickable",
                    onClick: ($event: any) => (_ctx.selectLocale(o.locale))
                  }, null, 8, _hoisted_3))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 2)
  ], 32))
}