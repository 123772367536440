
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import findTerms from '@/mixins/local/findTerms';
import { FuncSkew } from '@/components';
import { useLocale } from '@/composables';
import { LocaleOptions } from '@/config';
import {
  About,
  Benefits,
  Footer,
  Header,
  Hero,
  HowItWorks,
  Mobile,
  Offer,
  Specialists,
} from './_components';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  name: 'Welcome',
  components: {
    FuncSkew,
    About,
    Benefits,
    Footer,
    Header,
    Hero,
    HowItWorks,
    Mobile,
    Offer,
    Specialists,
  },
  mixins: [findTerms],
  setup() {
    const { setLocale } = useLocale();
    return { setLocale };
  },
  computed: {
    ...mapState('user', ['facility']),
    ...mapState('global', [
      'cities',
      'doctors',
      'specialities',
      'selectedCity',
      'selectedDoctor',
      'selectedSpeciality',
      'visitDate',
      'visitType',
      'visitKind',
    ]),
  },

  created() {
    this.initLocale();
  },

  methods: {
    ...mapActions('global', [
      'setCity',
      'setDoctor',
      'setSpeciality',
      'setVisitDate',
      'setVisitType',
      'setVisitKind',
    ]),

    initLocale(){
      const validateLangSupport = (lang: string | null | undefined): LocaleOptions | null => {
        const isSupported = !!lang && Object.values<string>(LocaleOptions).includes(lang);
        return isSupported ? lang as LocaleOptions : null;
      };

      const queriedLang = validateLangSupport(this.$route.query['lang'] as string);
      this.$router.replace(this.$router.currentRoute as RouteLocationRaw);

      const locallyStoredLang = validateLangSupport(localStorage.getItem('locale'));

      const locale: LocaleOptions = queriedLang ?? locallyStoredLang ?? LocaleOptions.PL;
      this.setLocale(locale);
    },
  },
});
