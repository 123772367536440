
import { defineComponent } from 'vue';
import { useLocale } from '@/composables';
import { LocaleOptions, Locales } from '@/config';
import { LocaleFlag } from '@/models';

export default defineComponent({
  name: "LocaleSwitch",
  setup() {
    const { setLocale } = useLocale();
    return { setLocale };
  },
  data() {
    return {
      isHovered: false,
      isExpanded: false,
    };
  },
  computed: {
    selectedLocaleOption(): LocaleFlag {
      return Locales.find(o => o.locale === this.$i.locale) || {
        file: 'pl.svg',
        locale: LocaleOptions.PL,
      };
    },
    otherLocaleOptions(): LocaleFlag[] {
      return Locales.filter(o => o.locale !== this.$i.locale);
    },
  },
  methods: {
    handleMousemove(isHovered: boolean): void {
      this.isHovered = isHovered;
      setTimeout(() => {
        this.isExpanded = this.isHovered;
      }, 100);
    },
    selectLocale(locale: LocaleOptions): void {
      this.setLocale(locale);
      this.isExpanded = false;
    },
    close(): void {
      this.isExpanded = false;
    },
  },
});
