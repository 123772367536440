import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "locale-switch-mobile" }
const _hoisted_2 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Locales, (o, i) => {
      return (_openBlock(), _createElementBlock("img", {
        key: i,
        src: require(`@/assets/images/flags/${o.file}`),
        class: _normalizeClass([o.locale === _ctx.$i.locale ? 'locale-switch-mobile__item--selected' : '', "locale-switch-mobile__item"]),
        onClick: ($event: any) => (_ctx.setLocale(o.locale))
      }, null, 10, _hoisted_2))
    }), 128))
  ]))
}