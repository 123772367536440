
import { defineComponent } from 'vue';
import { useLocale } from '@/composables';
import { Locales } from '@/config';

export default defineComponent({
  name: 'LocaleSwitchMobile',
  setup() {
    const { setLocale } = useLocale();
    return {
      Locales,
      setLocale,
    };
  },
});

