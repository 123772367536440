import { useEmitter } from "@/plugins";

export function useHandlers() {

  function handleHttpFailureResponse(error: { response: { status: number; }; }) {

    if (error.response && error.response.status) {

      const emitter = useEmitter();

      switch (error.response.status) {

      case 400:
        emitter.emit('notification-push', {
          content: 'Nie możesz wykonać wybranej akcji.',
          type: 'error',
        });
        break;
      case 401:
        emitter.emit('notification-push', {
          content: 'Nie posiadasz odpowiednich uprawnień.',
          type: 'error',
        });
        break;
      default:
        emitter.emit('notification-push', {
          content: 'Wystąpił błąd. Obsługa techniczna została automatycznie poinformowana o zaistniałej sytuacji.',
          type: 'error',
        });

      }

    }
  }

  return {
    handleHttpFailureResponse,
  };
      
}
