<template>
  <div class="func-search-form">
    <div class="func-search-form__nav">
      <div
        class="nav-item"
        :class="{'nav-item--active': visitType === 'evisit'}"
        @click="visitType = 'evisit'"
      >
        <p>
          <FuncTextWithSlots :items="dict.eVisit">
            <template #bold="{data}">
              <br>
              <b>{{ data }}</b>
            </template>
          </FuncTextWithSlots>
        </p>
      </div>
      <div
        class="nav-item nav-item--second"
        :class="{'nav-item--active': visitType === 'stationary'}"
        @click="visitType = 'stationary'"
      >
        <p>
          <FuncTextWithSlots :items="dict.stationaryVisit">
            <template #bold="{data}">
              <br>
              <b>{{ data }}</b>
            </template>
          </FuncTextWithSlots>
        </p>
      </div>
    </div>
    <div class="func-search-form__content">
      <div
        v-if="v$.$error"
        class="form-error"
      >
        {{ dict.completeDataPrompt }}
      </div>
      <div class="search-row">
        <div class="search-row__icon">
          <img
            src="~@/assets/icons/doctor-ico-white.svg"
            alt="asset"
          >
        </div>
        <div
          class="search-row__content"
          :class="{'search-row__content--error': v$.selectedDoctor.$error}"
        >
          <VSelect
            v-model="selectedDoctorOrSpecialty"
            :placeholder="dict.selectSpecialtyOrDoctor"
            :promoted-items="doctors"
            :items="specialities"
            :searchable="true"
            @live="searching('specialty', $event);"
          >
            <template #display="{item}">
              <span v-if="item.specialties">{{ item.first_name }} {{ item.last_name }}</span>
              <span v-else>{{ item.name }}</span>
            </template>

            <template #promotedTitle>
              {{ dict.doctor }}
            </template>

            <template #promotedItem="{item}">
              {{ item.first_name }} {{ item.last_name }}<br> <span>{{ item.specialties && item.specialties.length ?
                `(${item.specialties.join(', ')})` : `(${item.prof_code})` }}</span>
            </template>

            <template #itemsTitle>
              {{ dict.specialty }}
            </template>

            <template #item="{item}">
              {{ item.name }}
            </template>

            <template #noItems>
              {{ dict.noSpecialties }}
            </template>
          </VSelect>
          <small v-if="!v$.selectedDoctor.isValid">{{ dict.requiredField }}</small>
        </div>
      </div>
      <div
        class="search-row"
        :class="{'search-row--disabled': visitType !== 'stationary'}"
      >
        <div class="search-row__icon search-row__icon--city">
          <img
            src="~@/assets/icons/city-ico-white.png"
            alt="asset"
          >
        </div>
        <div
          class="search-row__content"
          :class="{'search-row__content--error': v$.selectedCity.$error}"
        >
          <VSelect
            v-model="selectedCity"
            :promoted-items="cities.length > 1 && !selectedSpeciality ? [{ name: 'Dowolne' }] : []"
            :items="cities"
            label="name"
            :placeholder="dict.selectCity"
            :searchable="true"
            @live="searching('city', $event);"
          >
            <template #item="{item}">
              {{ item.name }}<br> <span>({{ item.state_name_pl }})</span>
            </template>

            <template #noItems>
              {{ dict.noCities }}
            </template>
          </VSelect>
          <small v-if="!v$.selectedCity.isValid">{{ dict.requiredField }}</small>
        </div>
      </div>
      <div class="search-row">
        <div class="search-row__icon">
          <img
            src="~@/assets/icons/calendar-ico-white.svg"
            alt="asset"
          >
        </div>
        <div class="search-row__content--splited">
          <div
            class="search-row__content"
            :class="{'search-row__content--error': v$.visitDate.$error}"
          >
            <datepicker
              v-model="visitDate"
              :placeholder="dict.selectDate"
              :clearable="false"
              :days="dict.dayNames"
              :months="dict.monthNames"
              :disable-before="`${new Date()}`"
              display-format="dd.mm.yyyy"
              output-format="yyyy-mm-dd"
            />
            <small v-if="!v$.visitDate.required">{{ dict.requiredField }}</small>
          </div>
          <switcher
            v-if="!app__isMobile()"
            v-model="isNfz"
            :off-label="dict.visitType.private"
            :on-label="dict.visitType.nfz"
          />
        </div>
      </div>
      <switcher
        v-if="app__isMobile()"
        v-model="isNfz"
        :off-label="dict.visitType.private"
        :on-label="dict.visitType.nfz"
      />
      <div class="content-footer">
        <UiBtn
          type="search"
          class="cta-btn"
          :default-icon="true"
          @click="redirectSearch()"
        >
          {{ dict.search }}
        </UiBtn>
        <div
          v-if="online > 0"
          class="online"
        >
          <div class="online__counter">
            {{ online }}
          </div>
          <p>{{ dict.doctorsAvailable }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import dateFormat from 'dateformat';
import VSelect from '@/components/ui/vselect/vselect.vue';
import datepicker from '@/components/ui/datepicker/datepicker.vue';
import switcher from '@/components/ui/switcher/switcher.vue';
import { FuncTextWithSlots } from '@/components/functional/TextWithSlots';
import { UiBtn } from '@/components';
import debounce from 'lodash.debounce';
import { ExternalLinks } from '@/config';
import { defineComponent } from 'vue';
import { useHandlers } from '@/composables';

export default defineComponent({
  name: 'FuncSearchForm',
  components: {

    VSelect,
    datepicker,
    switcher,
    FuncTextWithSlots,
    UiBtn,

  },
  props: {

    data: {
      type: Object,
      default: () => {},
      required: true,
    },

    facility: {
      type: Object,
      default: () => {},
      required: false,
    },

    type: {
      type: String,
      default: 'evisit',
    },

    kind: {
      type: String,
      default: 'Prywatna',
    },

    online: {
      type: Number || String,
      default: 0,
    },

  },
  setup() {
    const { handleHttpFailureResponse } = useHandlers();
    return {
      v$: useVuelidate(),
      handleHttpFailureResponse,
    };
  },
  data(){
    return {

      cities: [],
      doctors: [],
      specialities: [],

      visitType: 'evisit',
      selectedDoctor: null,
      selectedSpeciality: null,

      selectedCity: null,
      visitDate: null,
      isNfz: false,

      search: {
        cities: '',
        doctors: '',
        specialities: '',
      },

    };
  },
  validations: {
    visitDate: { required },
    selectedDoctor: {
      isValid() {
        if (this.selectedDoctor || this.selectedSpeciality) return true;
        return false;
      },
    },
    selectedCity: {
      isValid() {
        if (this.visitType !== 'evisit'){
          if (this.selectedCity) return true;
          return false;
        }
        return true;
      },
    },
  },
  computed: {

    dict() {
      return this.$i.tr().visitSearch;
    },

    selectedDoctorOrSpecialty: {
      get() {
        if (this.selectedDoctor) return this.selectedDoctor;
        if (this.selectedSpeciality) return this.selectedSpeciality;
        return '';
      },
      set(data) {
        this.selectDoctor(data);
      },
    },

    doctorsComp(){
      if (!this.search.doctors) return this.doctors;

      const results = this.doctors.filter(item => {

        const doctor = `${item.first_name} ${item.last_name}`.toLowerCase();
        return doctor.includes(this.search.doctors.toLowerCase());

      });

      return results;

    },

  },
  watch: {

    data: {
      handler: function(val, oldVal) { // eslint-disable-line
        this.setData();
      },
      deep: true,
    },

    type(){
      this.visitType = this.type;
      this.v$.$reset();
    },

    kind(){
      this.setKind();
    },

    selectedSpeciality(val, oldVal){
      if (!oldVal && val) this.selectedCity = null;
    },

    selectedDoctor(val, oldVal){
      if (!oldVal && val) this.selectedCity = { name: 'Dowolne' };
    },

  },
  created(){
    this.init();
  },
  methods: {

    init(){

      const loader = this.app__setLoader();
      this.setData();
      this.app__loaderDequeue(loader);

    },

    setData(){

      if (this.type) this.visitType = this.type;
      if (this.kind) this.setKind();

      if (!Object.keys(this.data).length) return;

      this.cities = this.data.cities || [];
      this.doctors = this.data.doctors || [];
      this.specialities = this.data.specialities || [];

      this.selectedDoctor = this.data.selectedDoctor || null;
      this.selectedCity = this.data.selectedCity || null;
      this.selectedSpeciality = this.data.selectedSpeciality || null;
      this.visitDate = this.data.visitDate || dateFormat(new Date(), 'yyyy-mm-dd');

    },

    setKind() {
      this.isNfz = this.kind === 'NFZ';
    },

    searching: debounce(function(type, query) { // eslint-disable-line
      if (['person', 'specialty'].includes(type)) {

        if (!query){
          this.doctors = this.data.doctors || [];
          this.specialities = this.data.specialities || [];
          return;
        }

        const promises = [this.getSugestions('person', query), this.getSugestions('specialty', query)];

        Promise.all(promises).then(data => {
          this.doctors = [...data[0]];
          this.specialities = [...data[1]];
        });
        return;
      }

      if (['city'].includes(type)) {

        if (!query){
          this.cities = this.data.cities || [];
          return;
        }

        const promises = [this.getLocations(query)];

        Promise.all(promises).then(data => {
          [this.cities] = data;
        });
        return;
      }

      this.getSugestions(type, query).then(data => { this[type] = data; });
    }, 500),

    getSugestions(type, query){

      return new Promise((resolve, reject) => {

        this.app__getToken('mydr-website-token').then(token => {

          this.$http.get('visits/search_autosuggest/', {
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
              'Content-Type': 'Application/json',
            },
            params: {
              search_type: type,
              limit: 10,
              q: query,
            },
          }).then(response => {

            resolve(response.data);

          }).catch(error => {
            this.handleHttpFailureResponse(error);
            reject(new Error(error));
          });

        });

      });

    },

    getLocations(q) {

      return new Promise((resolve, reject) => {

        this.app__getToken('mydr-website-token').then(token => {

          this.$http.get('location/search_location/', {
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
              'Content-Type': 'Application/json',
            },
            params: {
              limit: 10,
              q,
            },
          }).then(res => {
            const cities = res.data.map(item => ({ name: item.place_name, state_name_pl: item.state_name_pl, state_name: item.state_name }));
            resolve(cities);
          }).catch(error => {
            reject(error);
          });

        });

      });
    },

    selectDoctor(data){

      if (data.specialties) {
        this.selectedSpeciality = null;
        this.selectedDoctor = data;
      }

      if (data.name) {
        this.selectedDoctor = null;
        this.selectedSpeciality = data;
      }

    },

    showTab(tab){
      const types = this.facility.patient_visit_performances_options;
      return types.find(ob => ob.label === tab);
    },

    redirectSearch() {

      this.v$.$touch();
      if (this.v$.$invalid) return;

      const data = {
        kind: this.isNfz ? 'NFZ' : 'Prywatna',
        doctor: this.selectedDoctor ? JSON.stringify({
          id: this.selectedDoctor.id,
          name: `${this.selectedDoctor.first_name} ${this.selectedDoctor.last_name}`,
          search_id: this.selectedDoctor.search_id,
        }) : null,
        speciality: this.selectedSpeciality?.name || null,
        location: (this.selectedCity && this.selectedCity.name !== 'Dowolne') ? JSON.stringify({
          place_name: this.selectedCity.name,
          state_name: this.selectedCity.state_name,
        }) : null,
        date: this.visitDate,
        search: true,
      };

      const queryString = [];

      Object.entries(data).forEach(([key, value]) => {
        if (value) queryString.push(`${key}=${value}`);
      });

      const newVisitUrl = `${ExternalLinks.MYDR_NEW_VISIT(this.visitType)}?${queryString.join('&')}`;

      window.location.assign(newVisitUrl);
    },
  },
});
</script>

<style lang="scss" src="./styles.scss" />
