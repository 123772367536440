
import {
  LocaleSwitch,
  LocaleSwitchMobile,
} from './_components';
import { UiBtn } from '@/components';
import { ExternalLinks } from '@/config';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

interface MenuItem {
  label: string;
  href: string;
}

export default defineComponent({
  name: 'Header',
  components: {
    LocaleSwitch,
    LocaleSwitchMobile,
    UiBtn,
  },
  data(){
    return {
      menuOpen: false,
      isHovered: false,
      isExpanded: false,
      width: window.innerWidth,
    };
  },
  computed: {
    ...mapState('user', ['facility']),
    URI_LOGIN: () => ExternalLinks.MYDR_LOGIN,
    MYDR_EDM: () => ExternalLinks.MYDR_EDM,
    MYDR_EDM_LOGIN: () => ExternalLinks.MYDR_EDM_LOGIN,
    dict() {
      return this.$i.tr().header;
    },
    items(): MenuItem[] {
      return [
        {
          label: this.$i.tr().header.nav.howItWorks,
          href: '#how-it-works',
        },
        {
          label: this.$i.tr().header.nav.aboutUs,
          href: '#about-us',
        },  
        {
          label: this.$i.tr().header.nav.mobileApp,
          href: '#mobile-app',
        },
        {
          label: this.$i.tr().header.nav.clinicZone,
          href: this.MYDR_EDM,
        },
      ];
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    hackHero(){
      const hero = document.querySelector('#hero');
      if (hero && !hero.classList.contains('hacky-scroll')) hero.classList.add('hacky-scroll');
    },
    logoClick(){
      if (this.$route.path !== '/'){
        this.$router.push('/');
        return;
      }
      window.scrollTo(0, 0);
    },
    handleMousemove(isHovered: boolean): void {
      this.isHovered = isHovered;
      this.isExpanded = this.isHovered;
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
});
