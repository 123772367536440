<template>
  <div>
    <div
      v-for="i in 37"
      :key="i"
      ref="pin"
      :class="`loc-${i}`"
    >
      <!-- Wrapper neccesary to separate transform and animate.css -->
      <img
        src="@/assets/icons/localization-map.svg"
        alt="map"
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import visitAnims from "../../_animations";

export default defineComponent({
  name: 'SpecialistsMap',
  mounted() {
    visitAnims.animPinsScroll(this.$refs);
  },
});
</script>

<style scoped lang="scss" src="./styles.scss" />
