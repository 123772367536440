
import { defineComponent } from 'vue';

export default defineComponent({

  name: 'UiBtn',
  props: {

    type: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    defaultIcon: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: '',
    },

    background: {
      type: String,
      default: '',
    },

  },
  methods: {

    getClasses(){

      switch (this.type){

      case 'add':
        return 'ui-btn--add';
      case 'next':
        return 'ui-btn--next';
      case 'login':
        return 'ui-btn--login';
      case 'search':
        return 'ui-btn--search';
      case 'register':
        return 'ui-btn--register';
      case 'cancel':
        return 'ui-btn--cancel';
      default:
        return 'ui-btn--default';

      }

    },

  },

});

