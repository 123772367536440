
import { UiTile } from '@/components';
import animations from "./animations";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Offer',
  components: {
    UiTile,
  },
  computed: {
    dict() {
      return this.$i.tr().offer;
    },
  },
  mounted() {
    animations();
  },
});
