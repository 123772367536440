<template>
  <div class="ui-switcher">
    <p v-if="offLabel">
      {{ offLabel }}
    </p>
    <div
      class="ui-switcher-container"
      :class="{'checked': valueCheck, 'disabled': disabled}"
      @click="checkValue()"
    >
      <div class="ui-switcher-container__slider round" />
    </div>
    <p v-if="onLabel">
      {{ onLabel }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({

  name: 'Switcher',
  props: {

    modelValue: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    offLabel: {
      type: String,
      default: '',
    },

    onLabel: {
      type: String,
      default: '',
    },

  },
  emits:['update:modelValue'],
  data(){
    return {
      valueCheck: false,
    };
  },
  watch:{

    modelValue(){
      this.valueCheck = this.modelValue;
    },

  },
  created(){
    this.valueCheck = this.modelValue;
  },
  methods: {

    checkValue(){

      if (this.disabled) return;

      if (this.valueCheck){
        this.valueCheck = false;
      } else {
        this.valueCheck = true;
      }

      this.$emit('update:modelValue', this.valueCheck);

    },

  },

});

</script>

<style lang="scss" src="./switcher.scss" />
