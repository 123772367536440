import { Linear, Power2, TimelineMax } from "gsap/all";

const animations = {
  animBoxScrollDesktop(refs) {
    const { pcTile, helpTile, formTile } = refs;
    // const els = [pcTile, helpTile, formTile];

    const tl = new TimelineMax({ repeat: -1, delay: 0 });
    const tl1 = new TimelineMax({ repeat: -1, delay: 2 });
    const tl2 = new TimelineMax({ repeat: -1, delay: 4 });

    tl.fromTo(pcTile, 1, { opacity: 0 }, { opacity: 1 })
      .to(pcTile, 6, {
        x: "1000%",
        ease: Linear.easeNone,
        delay: -1,
      })
      .to(
        pcTile,
        3,
        {
          y: "-100%",
          ease: Power2.easeInOut,
          repeat: 1,
          yoyo: true,
        },
        0,
      )
      .to(pcTile, { opacity: 0, delay: -0.5 });

    tl1.fromTo(helpTile, 1, { opacity: 0 }, { opacity: 1 })
      .to(helpTile, 6, {
        x: "1000%",
        ease: Linear.easeNone,
        delay: -1,
      })
      .to(
        helpTile,
        3,
        {
          y: "-100%",
          ease: Power2.easeInOut,
          repeat: 1,
          yoyo: true,
        },
        0,
      )
      .to(helpTile, { opacity: 0, delay: -0.5 });

    tl2.fromTo(formTile, 1, { opacity: 0 }, { opacity: 1 })
      .to(formTile, 6, {
        x: "1000%",
        ease: Linear.easeNone,
        delay: -1,
      })
      .to(
        formTile,
        3,
        {
          y: "-100%",
          ease: Power2.easeInOut,
          repeat: 1,
          yoyo: true,
        },
        0,
      )
      .to(formTile, { opacity: 0, delay: -0.5 });
  },

  animBoxScrollMobile(refs) {
    const { pcTile, helpTile, formTile } = refs;

    const tl = new TimelineMax({ repeat: -1, delay: 0.2 });
    const tl1 = new TimelineMax({ repeat: -1, delay: 1 });
    const tl2 = new TimelineMax({ repeat: -1, delay: 2 });

    tl.fromTo(
      pcTile,
      4,
      {
        opacity: 0,
      },
      {
        y: "500%",
        opacity: 1,
        ease: Linear.easeNone,
      },
    )
      .to(
        pcTile,
        2,
        {
          x: "-200%",
          ease: Power2.easeInOut,
          repeat: 1,
          yoyo: true,
        },
        0,
      )
      .to(pcTile, { opacity: 0, delay: -0.5 });

    tl1.fromTo(
      helpTile,
      4,
      {
        opacity: 0,
      },
      {
        y: "500%",
        opacity: 1,
        ease: Linear.easeNone,
      },
    )
      .to(
        helpTile,
        2,
        {
          x: "200%",
          ease: Power2.easeInOut,
          repeat: 1,
          yoyo: true,
        },
        0,
      )
      .to(helpTile, { opacity: 0, delay: -0.5 });

    tl2.fromTo(
      formTile,
      4,
      {
        opacity: 0,
      },
      {
        y: "500%",
        opacity: 1,
        ease: Linear.easeNone,
      },
    )
      .to(
        formTile,
        2,
        {
          x: "-200%",
          ease: Power2.easeInOut,
          repeat: 1,
          yoyo: true,
        },
        0,
      )
      .to(formTile, { opacity: 0, delay: -0.5 });
  },
};
export default animations;
