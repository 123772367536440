import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "footer-nav" }
const _hoisted_2 = { class: "footer-nav__header" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "footer-nav__item-icon"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "footer-nav__column"
      }, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(group.name), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.links, (link, linkindex) => {
          return (_openBlock(), _createElementBlock("a", {
            key: linkindex,
            class: _normalizeClass(["footer-nav__item", link.class]),
            href: link.url,
            target: "_blank"
          }, [
            (link.icon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("i", {
                    class: _normalizeClass(["lsi", `lsi-${link.icon}`])
                  }, null, 2)
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(link.name) + " ", 1),
            (link.badge)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "footer-nav__item-badge",
                  src: require(`@/assets/images/${link.badge}`)
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ], 10, _hoisted_3))
        }), 128))
      ]))
    }), 128))
  ]))
}