<template>
  <div class="section-hero">
    <div class="section-hero-container">
      <div
        v-if="$i.locale === LocaleOptions.UK"
        class="list-of-facilities"
      >
        <h2 class="list-of-facilities__header">
          Заклади, які приймають <b>громадян України</b>
        </h2>
        <div class="list-of-facilities__container">
          <div
            v-for="(facility, index) in facilityList"
            :key="index"
          >
            <div class="list-of-facilities__row">
              <div class="list-of-facilities__column1">
                {{ facility.name }}
              </div>
              <div class="list-of-facilities__column2">
                {{ facility.addressLine1 }}<br>{{ facility.addressLine2 }}
                <div v-if="facility.telephone">
                  Tel.: {{ facility.telephone }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="section-hero-container__image"
      >
        <img
          class="woman-ilustration"
          src="~@/assets/images/decorations/hero-woman.svg"
          alt="Woman"
        >
        <div class="phone-box">
          <img
            src="~@/assets/images/decorations/hero-phone.svg"
            alt="Mobile phone"
          >
        </div>
        <img
          class="man-box"
          src="~@/assets/images/decorations/hero-man.svg"
          alt="Man"
        >
      </div>
      <div class="section-hero-container__content">
        <FuncSearchForm
          :type="visitType"
          :kind="visitKind"
          :data="getSavedData"
          @search="saveSearch($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import findTerms from '@/mixins/local/findTerms';
import {
  FuncSearchForm,
} from '@/components';
import { LocaleOptions } from '@/config';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Hero',
  components: {
    FuncSearchForm,
  },
  mixins: [findTerms],
  data() {
    return {
      LocaleOptions,
      facilityList: [
        {
          name: 'Izabela Nieznańska NZOZ Praktyka Lekarza Rodzinnego "Życie" s.c.',
          addressLine1: 'ul. Lubartowska 64',
          addressLine2: '20-094 Lublin',
        },
        {
          name: 'Samodzielny Publiczny Zakład Opieki Zdrowotnej w Motyczu',
          addressLine1: 'Marynin 59',
          addressLine2: '21-030 Motycz',
          telephone: '815 031 009',
        },
        {
          name: 'Niepubliczny Zakłaz Opieki Zdrowotnej "Melisa" Ewa Turska i Partnerzy Spółka Lekarska',
          addressLine1: 'ul. Niepodległości 9',
          addressLine2: '20-246 Lublin',
          telephone: '817 473 072',
        },
        {
          name: 'Paprocki Jarema Niepubliczny Zakład Opieki Zdrowotnej w Starościnie',
          addressLine1: 'Starościn Kol. 7a',
          addressLine2: '21-132 Kamionka',
          telephone: '818 529 356',
        },
      ],
    };
  },
  computed: {
    ...mapState('user', ['facility']),
    ...mapState('global', [
      'cities',
      'doctors',
      'specialities',
      'visitDate',
      'visitType',
      'visitKind',
    ]),
    getSavedData(){
      return {
        cities: this.cities,
        doctors: this.doctors,
        specialities: this.specialities,
        visitDate: this.visitDate,
      };
    },
  },
  methods: {
    ...mapActions('global', [
      'setCity',
      'setDoctor',
      'setSpeciality',
      'setVisitDate',
      'setVisitType',
      'setVisitKind',
    ]),
    saveSearch(data){
      this.setCity(data.city);
      this.setDoctor(data.doctor);
      this.setSpeciality(data.speciality);
      this.setVisitDate(data.visitDate);
      this.setVisitType(data.visitType);
      this.setVisitKind(data.visitKind);
      this.app__findFreeTerms();
    },
  },
});
</script>

<style lang="scss" src="./styles.scss" />
