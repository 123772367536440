import { gsap } from "gsap/all";

function animations() {
  gsap.fromTo(
    ".tick",
    {
      width: "0",
    },
    {
      width: "2rem",
      ease: "power1.out",
      repeat: -1,
      repeatDelay: 3,
      duration: 1,
    },
  );

  gsap.to(".circle-arrow", {
    rotation: "-=360",
    ease: "power1.out",
    repeat: -1,
    repeatDelay: 3,
    delay: 0.5,
    duration: 1,
  });

  gsap.to(".day-night", {
    rotation: "+=180",
    ease: "power1.out",
    repeat: -1,
    repeatDelay: 3,
    delay: 1,
    duration: 1,
  });
}
export default animations;
