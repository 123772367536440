
import animations from "./animations";
import { defineComponent } from 'vue';
import { BenefitNames } from "@/models";
import { UiBtn } from '@/components';

interface Benefit {
  name: BenefitNames;
  img: string;
}

export default defineComponent({
  name: 'Benefits',
  components: {
    UiBtn,
  },
  data() {
    return {
      benefits: [
        {
          name: 'prescription',
          img: 'recipt-violet',
        },
        {
          name: 'makeAppointment',
          img: 'help-violet',
        },
        {
          name: 'laboratoryTests',
          img: 'lab-violet',
        },
        {
          name: 'medicalHistory',
          img: 'history-violet',
        },
      ] as Benefit[],
    };
  },
  computed: {
    dict() {
      return this.$i.tr().benefits;
    },
  },
  mounted() {
    animations();
  },
});
