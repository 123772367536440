
import { defineComponent, PropType } from 'vue';

type Item = string | {name: string; data?: any};

export default defineComponent({
  name: 'FuncTextWithSlots',
  props: {
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
  },
  methods: {
    isString(item: Item): boolean {
      return typeof item === 'string';
    },
  },
});

