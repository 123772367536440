import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

function animations() {
  gsap.fromTo(
    ".profits-list__item",
    {
      x: 900,
      opacity: 0,
    },
    {
      x: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".profits-list",
        start: "bottom 100%",
      },
      stagger: {
        amount: 0.5,
      },
      ease: "power1.out",
    },
  );
}

export default animations;
