import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "func-text-with-slots" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("span", {
        key: i,
        class: "func-text-with-slots__item"
      }, [
        (_ctx.isString(item))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(item), 1)
            ], 64))
          : _renderSlot(_ctx.$slots, item.name, {
              key: 1,
              data: item.data
            })
      ]))
    }), 128))
  ]))
}